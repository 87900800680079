import React, { Fragment } from "react";
import { ThemeProvider } from "styled-components";
import { Modal } from "@redq/reuse-modal";
import Sticky from "react-stickynode";
import { DrawerProvider } from "common/contexts/DrawerContext";
import { saasClassicTheme } from "common/theme/saasClassic";
import { ResetCSS } from "common/assets/css/style";
import {
  GlobalStyle,
  ContentWrapper,
} from "containers/SaasClassic/saasClassic.style";
import Navbar from "Containers/Navbar";
import "@redq/reuse-modal/es/index.css";
import Seo from "components/seo";
import { graphql, StaticQuery } from "gatsby";
import Footer from "Containers/Footer";
import ScrollItems from "../../Containers/ScrollItems";
import KonditourTop from "../../Containers/Banner/konditour";
import AppImage from "../../images/footerapp.svg";
import PlaystoreImage from "../../images/footerplay.svg";
import Image from "common/components/Image";
import Heading from "common/components/Heading";
import Fade from "react-reveal/Fade";

const SaasClassic = ({ location }) => {
  const [Tours, setTours] = React.useState();
  const [lang, setLang] = React.useState("He");
  const [source, setSource] = React.useState("Dguide");
  const [pImage, setPImage] = React.useState(null);
  const [videoObj, setVideoObj] = React.useState(null);

  React.useEffect(() => {
    const params = new URLSearchParams(location.search);
    let source = params.get("source");

    if (source) {
      setSource(source);
    }
    if (pImage) {
      let p_video = {
        url: "https://www.youtube.com/embed/4zxHq60a96g",
        poster_url: pImage,
      };
      setVideoObj(p_video);
    }
  }, [Tours]);
  const description =
    "בעזרת האפליקצייה תוכלו להמשיך את המסע הקולינרי עם מדריכי הקולינריה הטובים ביותר אצלכם בנייד . בכל סיור מחכים לכם הדרכות על העסקים השונים, פעילויות, המלצות קולינריות, והטבות בזמן שלכם ובקצב שלכם!";
  const section_1 = {
    title: { He: "בעקבות הקדושים", En: "" },
    slogan: { He: "", En: "" },
    tours: [
      "8Vs1GNvyr56RH3jsBq1n",
      "UdxGCzh3WBb4HvufyXI0",
      "orR8LG1DR1LDaTZ9it7w",
      "b16wOFd4frb3mLmYBV4o",
      "hoFEt5Ay3IcEbXp5qeHM",
    ],
  };
  const section_2 = {
    title: { He: "המבוקשים ביותר", En: "" },
    slogan: { He: "", En: "" },
    tours: [
      "lfngCB2AQGIoIdVkiIdu",
      "2RsJUf7rYXZpZnkfOCqr",
      "vEkQmtiVgM96L0BPuB54",
      "XhYJ2O5vESHnjtqjrQTd",
    ],
  };

  const section_3 = {
    title: { He: "מומלצים לשבת", En: "" },
    slogan: { He: "", En: "" },
    tours: ["8Vs1GNvyr56RH3jsBq1n", "XhYJ2O5vESHnjtqjrQTd"],
  };
  const section_4 = {
    title: { He: "מומלצים לשישי", En: "" },
    slogan: { He: "", En: "" },
    tours: [
      "b16wOFd4frb3mLmYBV4o",
      "2RsJUf7rYXZpZnkfOCqr",
      "vEkQmtiVgM96L0BPuB54",
    ],
  };

  return (
    <StaticQuery
      query={graphql`
        query konditourHePageQuery {
          allTour {
            nodes {
              accessible
              all_media {
                name
                order
                poster_url
                profile
                type
                url
                poster_name
              }
              description
              distance
              duration
              hours_range {
                max
                min
              }
              id
              location
              main_sentense
              price
              rating {
                total
                votes
              }
              title
              tour_guide {
                email
                full_name
                id
                profile_image
              }
              type
              hostedBy
              tour_image_obj {
                title
                url
              }
            }
          }
          image: file(relativePath: { eq: "konditour.jpeg" }) {
            id
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      `}
      render={(data) => {
        setPImage(data.image);
        setTours(data.allTour.nodes);

        if (!Tours) return null;
        let sec_1 = Tours.filter(function (tour) {
          //return section_1.tours.indexOf(tour.id) === -1;
          return section_1.tours.includes(tour.id);
        });
        let sec_2 = Tours.filter(function (tour) {
          //return section_1.tours.indexOf(tour.id) === -1;
          return section_2.tours.includes(tour.id);
        });
        let sec_3 = Tours.filter(function (tour) {
          //return section_1.tours.indexOf(tour.id) === -1;
          return section_3.tours.includes(tour.id);
        });
        let sec_4 = Tours.filter(function (tour) {
          //return section_1.tours.indexOf(tour.id) === -1;
          return section_4.tours.includes(tour.id);
        });

        return (
          <ThemeProvider theme={saasClassicTheme}>
            <Fragment>
              <Seo
                title={`Dguide`}
                description={"Dguide all tours!"}
                lang={"he"}
                meta={[
                  "סיורים",
                  "סיורים בירושלים",
                  "טיול בירושלים",
                  "טיול",
                  "טיול למשפחה",
                  "מקומות לטייל בשבת",
                  "איפה אפשר לטייל",
                  "המלצות לטיולים",
                  "טיול יום",
                  "סיור יום",
                  "אטרקציות בירושלים",
                  "סיור עצמאי",
                  "מדריך טיולים",
                  "יום כיף",
                  "מה לעשות בירושלים",
                  "סיורים מודרכים בירושלים",
                ]}
                keywords={["סיור מודרך", "סיור עצמאי", `מה אפשר לעשות ב `]}
              />
              <Modal />
              <ResetCSS />
              <GlobalStyle />

              <ContentWrapper style={{ background: "#f9f9f9" }}>
                <Sticky top={0} innerZ={9999} activeClass="sticky-nav-active">
                  <DrawerProvider>
                    <Navbar
                      lang={lang}
                      setLang={setLang}
                      returnPayment={true}
                    />
                  </DrawerProvider>
                </Sticky>
                {videoObj && (
                  <KonditourTop tourTrail={videoObj} tourDesc={description} />
                )}
                <div id="111" key="123">
                  <Fragment>
                    <ScrollItems
                      tours={sec_1}
                      lang={lang}
                      title={section_1.title[lang]}
                      slogan={section_1.slogan[lang]}
                      carouselSelector="firstG"
                      source={source}
                    />
                  </Fragment>
                </div>
                <div id="222" key="456">
                  <Fragment>
                    <ScrollItems
                      tours={sec_2}
                      lang={lang}
                      title={section_2.title[lang]}
                      slogan={section_2.slogan[lang]}
                      carouselSelector="secG"
                      source={source}
                    />
                  </Fragment>
                </div>
                <Fragment>
                  <ScrollItems
                    tours={sec_3}
                    lang={lang}
                    title={section_3.title[lang]}
                    slogan={section_3.slogan[lang]}
                    carouselSelector="thirG"
                    source={source}
                  />
                </Fragment>
                <Fragment>
                  <ScrollItems
                    tours={sec_4}
                    lang={lang}
                    title={section_4.title[lang]}
                    slogan={section_4.slogan[lang]}
                    carouselSelector="fourG"
                    source={source}
                  />
                </Fragment>
                <Fragment></Fragment>
                <Footer />
              </ContentWrapper>
            </Fragment>
          </ThemeProvider>
        );
      }}
    />
  );
};
export default SaasClassic;
